import React from "react";
import "./header.scss";
import logo from "../images/logo.svg";

const Header = (props) => {
  return (
    <div className="header-wrapper">
      <img src={logo} alt="" />
    </div>
  );
};

export default Header;
