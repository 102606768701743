import React from "react";
import "./outsource.scss";
import outsourceImage from "../images/outsource.svg";

const Outsource = () => {
  return (
    <div className="outsource-wrapper">
      <h3>Outsource to grow your business</h3>
      <div className="outsource-block">
        <ul>
          <li>
            Payroll and Statutory Compliance are crucial process in a business.
          </li>
          <li>
            Accurate and timely process of Payroll and Statutory Remittance will
            help your business to grow better.
          </li>
          <li>
            Outsourcing these processes to the qualified experts at MASS Payroll
            Solutions to deliver in quick turn-around-time and allow you to
            focus on your core activities to grow your Business
          </li>
        </ul>
        <img src={outsourceImage} alt="" className="right-align" />
      </div>
    </div>
  );
};

export default Outsource;
