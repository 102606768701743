import React from "react";
import "./textbox.scss";

const Textbox = (props) => {
  const {
    label,
    name,
    value,
    maxLength,
    placeholderText,
    onChange,
    disabled,
    customClass,
  } = props;

  const handleOnChange = (e) => {
    onChange(e.target.value);
  };

  const getClassName = () => {
    let className = "";
    if (customClass) className += customClass;
    return className;
  };

  return (
    <div className={`textbox-wrapper `}>
      <label>{label}</label>
      <input
        type="text"
        name={name}
        value={value}
        placeholder={placeholderText}
        maxLength={maxLength}
        autoComplete="off"
        onChange={(e) => handleOnChange(e)}
        disabled={disabled && disabled}
        className={getClassName()}
      />
    </div>
  );
};

export default Textbox;
