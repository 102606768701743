import React from "react";
import "./benefits.scss";
import benefitsImage1 from "../images/benefits-1.png";
import benefitsImage2 from "../images/benefits-2.png";
import benefitsImage3 from "../images/benefits-3.png";

const Benefits = () => {
  return (
    <div className="benefits-wrapper">
      <h3>
        Benefits you get <br />
        from Mass Payroll Solutions
      </h3>
      <div className="benefits-block">
        <img src={benefitsImage1} alt="" />
        <ul>
          <li>
            Subject expertise for 2 decades (about 6 lakhs+ Payroll transactions
            p.a.)
          </li>
          <li>End to end Payroll Solutions</li>
          <li>Accurate and on-time Payroll process</li>
          <li>Timely update on acts and rule benefits </li>
        </ul>
      </div>
      <div className="benefits-block">
        <ul className="left-align">
          <li>Easy & quick employee payments</li>
          <li>Effortless statutory remittances with 100% accuracy </li>
          <li>Record maintenance in safe, trusted and secure Cloud platform</li>
          <li>24/7 easy access of employee information</li>
        </ul>
        <div className="text-align-right">
          <img src={benefitsImage2} alt="" />
        </div>
      </div>
      <div className="benefits-block">
        <img src={benefitsImage3} alt="" />
        <ul>
          <li>Increase in efficiency & productivity</li>
          <li>Cost effective, decreased infrastructure cost</li>
          <li>Minimized risk of penalties and maximum confident</li>
          <li>Easy employee database management </li>
        </ul>
      </div>
    </div>
  );
};

export default Benefits;
