import React from "react";
import "./footer.scss";
import logoIcon from "../images/logo.svg";
import locationIcon from "../images/location.svg";
import callIcon from "../images/call.svg";
import mailIcon from "../images/mail.svg";

import facebookIcon from "../images/facebook.svg";
import instagramIcon from "../images/instagram.svg";
import linkedinIcon from "../images/linkedin.svg";
import twitterIcon from "../images/twitter.svg";

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-top-block">
        <img src={logoIcon} alt="" className="logo" />
        <div></div>
        <div className="center-align social-media-icons">
          <img src={facebookIcon} alt="" className="logo" />
          <img src={instagramIcon} alt="" className="logo" />
          <img src={linkedinIcon} alt="" className="logo" />
          <img src={twitterIcon} alt="" className="logo" />
        </div>
      </div>
      <div className="footer-bottom-block">
        <div className="address-block">
          <img
            src={locationIcon}
            alt=""
            className="footer-bottom-block-icons"
          />
          <div>
            49, 9th Cross Street, Subhashree Nagar, Mugalivakkam, Chennai -
            600125 Tamil Nadu, India
          </div>
        </div>
        <div className="center-align">
          <img src={callIcon} alt="" className="footer-bottom-block-icons" />
          9840992989 / 7397393319
        </div>
        <div className="center-align">
          <img src={mailIcon} alt="" className="footer-bottom-block-icons" />
          masspayroll@outlook.com
        </div>
      </div>
      <div className="copyrights-block center-align">
        Copyright © 2021 MASS Payroll Solutions - All Rights Reserved.
      </div>
    </div>
  );
};

export default Footer;
