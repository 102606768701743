import React from "react";
import "./about-us.scss";
import mapImage from "../images/map.svg";

const AboutUs = () => {
  return (
    <div className="about-us-wrapper">
      <div className="about-us-content">
        <h3>About us</h3>
        <span>
          We are a payroll outsourcing service provider working with small and
          medium businesses throughout India. Our mission is to provide
          high-quality payroll solutions for all of our clients.
        </span>
      </div>
      <img src={mapImage} alt="" className="map" />
    </div>
  );
};

export default AboutUs;
