import React from "react";
import "./button.scss";

const Button = (props) => {
  const { disabled, text, customClass, onClick } = props;

  return (
    <input
      type="button"
      className={`button ${customClass && customClass}`}
      value={text}
      onClick={(e) => onClick(e)}
      disabled={disabled ? disabled : false}
    />
  );
};

export default Button;
