import React from "react";
import "./brochure.scss";
import brochureIcon from "../images/brochure.svg";

const Brochure = (props) => {
  return (
    <div className="brochure-wrapper">
      <div className="left-block">
        <h1>Outsource your payroll and make your life even more simpler</h1>
        <a
          href={`${process.env.PUBLIC_URL}/brochure.pdf`}
          download
          className="brochure-button"
        >
          <img src={brochureIcon} alt="" />
          Download brochure
        </a>
      </div>
      <div></div>
    </div>
  );
};

export default Brochure;
