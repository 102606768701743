import React from "react";
import "./home.scss";
import "../header";
import Header from "../header";
import Brochure from "./brochure";
import AboutUs from "./about-us";
import Benefits from "./benefits";
import Outsource from "./outsource";
import ContactUs from "./contact-us";
import Footer from "../components/footer";

const Home = (props) => {
  return (
    <div className="home-wrapper">
      <Header />
      <Brochure />
      <AboutUs />
      <Benefits />
      <Outsource />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Home;
