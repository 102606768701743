import React, { useState } from "react";
import "./contact-us.scss";
import Textbox from "../components/textbox";
import Button from "../components/button";
import arrowIcon from "../images/arrow.svg";
import whatsappIcon from "../images/whatsapp.svg";
import fs from "fs";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [isNameEmpty, setNameEmpty] = useState(false);
  const [isEmailEmpty, setEmailEmpty] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  const onConnectClick = () => {
    setNameEmpty(false);
    setEmailEmpty(false);

    if (!name) setNameEmpty(true);
    if (!email) setEmailEmpty(true);

    if (!isNameEmpty && !isEmailEmpty) {
      var fs = require("browserify-fs");

      //fs.mkdir("/home", function () {
      fs.writeFile(
        `${process.env.PUBLIC_URL}/contact-form.txt`,
        "Hello world!\n",
        function () {
          fs.readFile(
            `${process.env.PUBLIC_URL}/contact-form.txt`,
            "utf-8",
            function (err, data) {
              console.log(data);
            }
          );
        }
      );
      //});
      setSuccess(true);
      setName("");
      setEmail("");
    }
  };

  return (
    <div className="contact-us-wrapper">
      <h3>Contact us</h3>
      <div className="contact-us-block">
        <div>
          <h4>Looking forward for business relationship</h4>
          <ul>
            <li>
              We believe that a business with a great marketing team can make an
              impact on the world.
            </li>
            <li>
              Take the first step - Contact us, and together, we will reach your
              company's goals.
            </li>
            <li>
              <img src={arrowIcon} alt="" />
            </li>
          </ul>
        </div>
        <div className="contact-form-wrapper">
          <div className="contact-form">
            <Textbox
              name="name"
              label="Name"
              onChange={(text) => setName(text)}
              placeholderText="Enter your name"
              value={name}
              customClass={isNameEmpty && "error"}
            />
            <Textbox
              name="email"
              label="Email"
              onChange={(text) => setEmail(text)}
              placeholderText="Enter your email"
              value={email}
              customClass={isEmailEmpty && "error"}
            />
            <div className="right-align">
              <Button
                text="Connect"
                onClick={() => onConnectClick()}
                customClass="connect-button"
              />
            </div>
            {isSuccess && (
              <div className="success-block">
                Thanks. We'll get in touch with you soon!
              </div>
            )}
            <div className="border"></div>

            <a
              href="https://wa.me/+919840992989"
              target="_blank"
              rel="noreferrer noopener"
              className="whatsapp-button"
            >
              <img src={whatsappIcon} alt="" />
              Connect with whatsapp
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
